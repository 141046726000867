<template>
  <div class="about">
    <v-row class="ma-2">
      <v-col class="mx-auto" v-for="car in cars" v-bind:key="car.name">
        <itemcard :car="car"></itemcard>
      </v-col>      
    </v-row>
  </div>
</template>

<script>
import Itemcard from '@/components/Itemcard'

export default {
  components: {Itemcard},
  data:()=>{
    return {
      cars: [
      {name: "car1", price: 10000, lotno: 1},
      {name: "car2", price: 28000 , lotno: 2},
      {name: "car3", price: 50000, lotno: 3}
    ],
    }
  }
}
</script>
